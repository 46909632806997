<template>
  <v-hover v-slot="{ hover : hoverName }">
    <div :style="`color: ${wsWHITE}`" class="d-flex align-center justify-start py-2 px-5 ">
      <v-icon
        :color="wsWHITE"
        class="mr-3 transition-ease-in-out rounded "
        style="cursor: pointer;"
        :style="`background-color: ${ hoverName ? wsACCENT : 'transparent'}`"
        @click="$emit('toggle')"
      >
        {{ `mdi-menu-${!isShow ? 'right' : 'down'} `}}
      </v-icon>
      <h5>
        {{ title }}
        <span :style="`color: ${wsACCENT}; font-weight: 400; font-size: 12`">({{ length }})</span>
      </h5>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "wsDropdownHeader.vue",
  props: {
    hoverName: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    },
    length: {
      type: Number, String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
  }
}
</script>

<style scoped>
.v-icon, .v-icon::after {
  content: none !important;
}
</style>
