<template>
  <div v-if="isActiveSidebarWindow" style="min-width: 305px; height: 100%; background-color: #31485A; color: #fff" class="rounded ">

    <!--   Chat block -->
    <ws-sidebar-chat
      :user="user"
      v-if="checkedValue === 'chat'"
      :checked-value="checkedValue"
      @close="closeSidebarDialog"
    />

    <!--   Scripts block   -->
    <ws-sidebar-scripts
      v-if="checkedValue === 'scripts'"
      :checked-value="checkedValue"
      @close="closeSidebarDialog"
    />

    <!--   Members block   -->
    <ws-sidebar-participants
      v-if="checkedValue === 'participants'"
      :checked-value="checkedValue"
      @close="closeSidebarDialog"
    />

    <!--   Survey block   -->
    <ws-slider-surveys
      v-if="checkedValue === 'surveys'"
      :checked-value="checkedValue"
      @close="closeSidebarDialog"
    />

    <!--   Banner block   -->
    <ws-sidebar-banner
      v-if="checkedValue === 'banner'"
      :checked-value="checkedValue"
      @close="closeSidebarDialog"
    />
  </div>
</template>


<script>
import WsSidebarChat from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarChat.vue";
import WsSidebarParticipants from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarParticipants.vue";
import WsSidebarBanner from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarBanner.vue";
import WsSidebarScripts from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarScripts.vue";
import WsSliderSurveys from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarSurveys.vue";

export default {
  name: "wsRightSidebarContent.vue",
  components: {
    WsSliderSurveys,
    WsSidebarScripts,
    WsSidebarBanner,
    WsSidebarParticipants,
    WsSidebarChat
  },
  props: {
    checkedValue: {
      type: String,
      default: ''
    },
    isActiveSidebarWindow: {
      type: Boolean,
      default: false
    },
    user: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeSidebarDialog() {
      this.$emit('close', false)
    },
  }
}
</script>



<style scoped>

</style>
