<template>
  <v-sheet style="height: 100%; background-color: transparent" class="d-flex flex-column justify-space-between">
    <!--   Sidebar scripts header -->
    <ws-sidebar-window-header
      :title="$t('webinar.main.preview.notes')"
      @close="$emit('close')"
      style="position: relative; z-index: 0"
      :checked-value="checkedValue"
    />

    <v-divider :color="wsACCENT"/>

    <div class="rounded d-flex flex-column justify-space-between" style="height: calc(100% - 60px)">

      <!--   NO SCRIPTS -->
      <div v-if="!scriptsForWebinar.length" class="d-flex align-center justify-center px-7 text-center" style="color: #fff; height: 100%">
        <ws-sidebar-window-no-content
          icon="mdi-file"
          :title="$t('webinar.main.preview.no_scripts')"
          :subtitle="$t('webinar.main.preview.no_scripts_text')"
        />
      </div>

      <!--   Scripts list  -->
      <div v-else style="color: #fff" class="pa-4 scrollable">
        <v-hover v-slot="{ hover }" v-for="(script, index) in scriptsForWebinar" :key="index">
          <div class="mb-2" >

            <!--  Script item  -->
            <div
              class="rounded px-4 py-2"
              :style="`background-color: #3B5365; max-width: 100%; word-wrap: break-word; border: 1px solid ${ hover ? wsACCENT : 'transparent' };
                        cursor: ${ hover ? 'pointer' : 'auto' }`">
              <h5 class="my-3">{{ script.duration }} {{ $t('webinar.settings.minutes_short') }}</h5>
              <v-divider :color="wsACCENT"/>
              <p class="ma-0 py-2" style="font-size: 13px" > {{ script.text }} </p>
            </div>

          </div>
        </v-hover>
      </div>
    </div>
  </v-sheet>

</template>

<script>
import WsSidebarWindowNoContent
  from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowNoContent.vue";
import WsSidebarWindowHeader from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowHeader.vue";

export default {
  name: "wsSidebarScripts.vue",
  components: {WsSidebarWindowHeader, WsSidebarWindowNoContent},
  props: {
    checkedValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  computed: {
    scriptsForWebinar() {
      return [
        { duration: '10', text: 'Note text 1', uuid: this.$uuid.v4()},
        { duration: '20', text: 'Note text 2', uuid: this.$uuid.v4()},
        { duration: '15', text: 'Note text 3', uuid: this.$uuid.v4()},
      ]
    },
  }
}
</script>

<style scoped lang="scss">
.scrollable {
  overflow-y: scroll;
  height: 100%;

  &::v-deep {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #4E6678;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #4E6678;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
</style>
