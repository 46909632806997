<template>
  <v-sheet :color="VIDEO_IS_ON ? wsDARKER : 'transparent'" class="fill-height wsRounded overflow-hidden py-6 pr-6 display-bg" >
    <ws-button @click="connectToStream" label="connect"></ws-button>
    <div v-show="started" class="wsRoundedSemi video-container" >
      <video
          ref="video"
          autoplay
          class="mx-auto d-flex video"
      />
      <audio ref="audio" autoplay hidden playsinline />

    </div>

    <div v-if="!started" class="d-flex align-center justify-center pr-8 pt-3 fill-height wsRoundedSemi" style="position:relative;">

      <!-- Preview (awaiting before webinar) -->
      <div class="d-flex align-center justify-center" style="column-gap: 32px; width: 100%; ">
        <div style="width: 540px; height: 540px; object-fit: cover; position: relative">
          <img
            style="width: 100%; height: 100%; border-radius: 50%; position: absolute; top: 0; left: -60px"
            :src="WEBINAR.image && WEBINAR.image.trim() !== '' ? WEBINAR.image : require(`@/modules/webinars/assets/no_student_webinar.png`)"
            alt="webinar image">
        </div>
        <div class="wsWHITETEXT" >
          <h2 style="font-size: 22px">{{$t('Webinar')}} «{{WEBINAR.name}}» {{$t('webinar.student.start_in')}}:</h2>
          <h1 style="font-size: 112px; line-height: 150%">{{String(hour).padStart(2, '0')}}:{{String(minute).padStart(2, '0')}}</h1>
          <h5>{{$t('webinar.student.wait_start_label')}}</h5>
        </div>
      </div>
    </div>

  </v-sheet>
</template>

<script>
import webinars from "@modules/webinars/mixins/webinars";
import WsJanusClient from "@modules/webinars/plugins/wsJanusClient";

export default {
  name: "webinarVideoStudent",
  mixins: [webinars],
  data() {
    return {
      countdownMessage: '',
      hour: 0,
      minute: 0,
      second: 0,
      started : false
    }
  },
  computed: {
    loading() {
      return !this.WEBINAR.uuid
    },
    dateStart() {
      return this.WEBINAR.date_start
    },
  },
  methods: {
    async connectToStream() {
      let result = await this.$store.dispatch('stream/GET_JANUS_START_TOKEN' , this.WEBINAR.uuid)
      if (!result) {
        this.ERROR(this.$store.state.ajax.error)
      }

      const videoDom = this.$refs.video
      const audioDom = this.$refs.audio

      let janus = new WsJanusClient(result.server)
      this.janus = janus

      janus.startStream({
        token : result.token,
        roomId : this.WEBINAR.uuid,
        userId : 54321,

        videoCallback : (id, stream, on) => {
          // alert('I AM VIDEO CALLBACK')
          console.log("videoCallback", on, id, stream)
          videoDom.srcObject = stream
          this.started = true
        },

        audioCallback : (id, stream, on) => {
          console.log("audioCallback", on, id, stream)
          // if (!on) {
          //   // delete this.audios["audio" + id]
          //   console.log("audioCallback deleted")
          // } else {
          //   // this.audios["audio" + id] = stream
          //   this.audios.push(stream)
          console.log("audioCallback added")
          // }
          audioDom.srcObject = stream
        }
      })

    },

    countdown(dateStart) {
      const now = new Date().getTime();
      const webinarDate = new Date(dateStart)
      const distance = webinarDate - now

      if (distance > 24 * 60 * 60 * 1000) {
        this.hour = Math.floor(distance / (60 * 60 * 1000));
        this.minute = Math.floor((distance % (60 * 60 * 1000)) / (60 * 1000));
        this.second = Math.floor((distance % (60 * 1000)) / 1000);
      } else {
        this.hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.second = Math.floor((distance % (1000 * 60)) / 1000);
      }

      if (distance > 0) {
        setTimeout(() => {
          this.countdown(webinarDate);
          this.countdownMessage = '';
        }, 1000);
      } else {
        this.countdownMessage = this.$t('webinar.main.preview.starting');
        this.hour = '00';
        this.minute = '00';
        this.second = '00';
      }

    },
  },
  watch : {
    dateStart(val) {
      this.countdown(val)
    },
    async SELECTED_CAMERA() {
      await this.createStream()
    },
    async VIDEO_IS_ON() {
      await this.createStream()
    },
  },
  mounted() {
    this.countdown(this.dateStart);
  },
}
</script>

<style scoped>
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.skeleton-controls {
  background: linear-gradient(90deg, rgba(154, 178, 196, 0.52) 0.5%, rgba(154, 178, 196, 0.32) 30.5%, rgba(154, 178, 196, 0.00) 100%);
}
.display-bg {
  position: relative;
  background-image: url('~@/modules/webinars/assets/display_student_bg.png');
  background-size: cover;
}
</style>
