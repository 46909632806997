<template>
  <v-sheet style="height: 100%; background-color: transparent" class="d-flex flex-column justify-space-between">
    <!--   Sidebar participants header -->

    <ws-sidebar-window-header
      :title="$t('webinar.settings.members')"
      @close="$emit('close')"
      :checked-value="checkedValue"
    />

    <v-divider :color="wsACCENT"/>
    <div :class="`rounded d-flex flex-column ${webinarParticipants.length !== 0 ? 'justify-start' : 'justify-space-between'}`" style="height: calc(100% - 60px)">

      <!--   Moderators dropdown header -->
      <ws-dropdown-header
        hover-name="arrowHover"
        :is-show="showModeratorsList"
        :length="webinarModerators.length"
        :title="$t('webinar.settings.moderators')"
        @toggle="showModeratorsList = !showModeratorsList"
      />
      <!--   Moderators dropdown content -->
      <div v-if="showModeratorsList" >
        <div v-for="(moderator, index) in webinarModerators" :key="index + moderator.value" class="mb-2 py-2 px-4 d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <img v-if="moderator.avatar" :src="moderator.avatar ? require(`@/modules/webinars/assets/${moderator.avatar}`) : ''" alt="moderator avatar" class="mr-2 rounded-circle" style="width: 32px; height: 32px">
            <div v-else :style="`background-color: ${wsACCENT};width: 32px; height: 32px; padding: 4px 3px`" class="rounded-circle d-flex align-center justify-center mr-2">
              <h5 :style="`color: ${wsBACKGROUND}`">MC</h5>
            </div>

            <p style="color: #fff; font-weight: 400; font-size: 13px; margin: 0">
              {{moderator.text}}
              <span :style="`color: ${wsACCENT}; font-size: 12px`">{{ moderator.isLector ? `(${$t('webinar.main.preview.lector')})` : '' }}</span>
            </p>
          </div>
          <div>
            <v-icon color="#fff" class="mr-3">{{ moderator.isMicrophone ? 'mdi-microphone' : 'mdi-microphone-off' }}</v-icon>
            <v-icon color="#fff" >{{ moderator.isVideo ? 'mdi-video' : 'mdi-video-off' }}</v-icon>
          </div>
        </div>
      </div>

      <!--   Members dropdown -->
      <v-hover v-slot="{ hover : membersHover}" >
        <div :style="`background-color: ${membersHover && showParticipantsList ? '#3B5365' : 'transparent'}`" class="transition-ease-in-out">
          <!--   Members dropdown header -->
          <ws-dropdown-header
            hover-name="arrowMembersHover"
            :is-show="showParticipantsList"
            :length="webinarParticipants.length"
            :title="$t('webinar.settings.members')"
            @toggle="showParticipantsList = !showParticipantsList"
          />

          <!--   Members dropdown content -->
          <div v-if="showParticipantsList" >
            <v-hover v-slot="{ hover : eachMemberHover}" v-for="(member, index) in webinarParticipants" :key="index + member.value">
              <div class="mb-2 py-2 px-4 d-flex align-center justify-space-between transition-ease-in-out" :style="`background-color: ${eachMemberHover ? wsDARKER : 'transparent'}`">
                <div class="d-flex align-center">
                  <img v-if="member.avatar" :src="member.avatar ? require(`@/modules/webinars/assets/${member.avatar}`) : ''" alt="moderator avatar" class="mr-2 rounded-circle" style="width: 32px; height: 32px">
                  <div v-else :style="`background-color: ${wsACCENT};width: 32px; height: 32px; padding: 4px 3px`" class="rounded-circle d-flex align-center justify-center mr-2">
                    <h5 :style="`color: ${wsBACKGROUND}`">CV</h5>
                  </div>

                  <span style="font-weight: 400; font-size: 13px" :color="wsWHITE">{{member.name}}</span>
                </div>
                <div v-if="!eachMemberHover && selectedChatParticipant.value !== member.value">
                  <v-icon color="#fff" class="mr-3">{{ member.isMicrophone ? 'mdi-microphone' : 'mdi-microphone-off' }}</v-icon>
                  <v-icon color="#fff" >{{ member.isVideo ? 'mdi-video' : 'mdi-video-off' }}</v-icon>
                </div>

                <ft-select
                  v-if="eachMemberHover || selectedChatParticipant.value === member.value"
                  @expand="$event ? selectedChatParticipant = member : selectedChatParticipant = {}"
                  :items="chatParticipantActionsSelect"
                >
                  <v-hover v-slot="{ hover: dotsHover }">
                    <v-icon
                      :color="dotsHover ? wsWHITE : wsDARKLIGHT"
                      :style="`background-color: ${ dotsHover ? '#3B5365' : 'transparent'};`"
                      class="rounded transition-ease-in-out"
                    >
                      mdi-dots-horizontal
                    </v-icon>
                  </v-hover>
                </ft-select>
              </div>
            </v-hover>

          </div>
        </div>
      </v-hover>


      <!--   NO MEMBERS IN MEMBER-LIST -->
      <div v-if="webinarParticipants.length === 0" class="d-flex align-center justify-center px-7 text-center" style="color: #fff; height: 100%">
        <ws-sidebar-window-no-content
          icon="mdi-account-multiple-plus"
          :title="$t('webinar.main.preview.no_members')"
          :subtitle="$t('webinar.main.preview.no_members_text')"
        />
      </div>
    </div>
  </v-sheet>


</template>

<script>
import WsSidebarWindowNoContent
  from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowNoContent.vue";
import WsDropdownHeader from "@modules/webinars/components/Webinar/admin/room/UI/wsDropdownHeader.vue";
import WsSidebarWindowHeader from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowHeader.vue";

export default {
  name: "wsSidebarParticipants.vue",
  components: {WsSidebarWindowHeader, WsDropdownHeader, WsSidebarWindowNoContent},
  props: {
    checkedValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showModeratorsList: false,
      showParticipantsList: false,
      selectedChatParticipant: {},
      webinarParticipants: [
        { name: 'User Name', avatar: '', isVideo: false, isMicrophone: false, value: 'user-1'},
        { name: 'User Name 2', avatar: 'mod-avatar.png', isVideo: true, isMicrophone: false, value: 'user-2' },
        { name: 'User Name 3', avatar: '', isVideo: false, isMicrophone: true, value: 'user-3' },
      ],
      webinarModerators: [
        { text: 'Moderator', value: 'Moderator', avatar: 'mod-avatar.png', isVideo: false, isMicrophone: false, isLector: true },
        { text: 'Moderator 2', value: 'Moderator 2', avatar: '', isVideo: true, isMicrophone: true, isLector: false },
      ],
    }
  },
  computed: {
    chatParticipantActionsSelect() {
      return [
        {
          text: this.$t('webinar.main.preview.stop_access'),
          value: 'stop_access',
          action: () => { this.stopAccessToChat() }
        },
        {
          text: this.$t('webinar.main.preview.block_member'),
          value: 'block_member',
          action: () => { this.blockMemberInChat() }
        },
        {
          text: this.$t('webinar.main.preview.delete_member'),
          value: 'delete_member',
          action: () => { this.deleteMemberFromChat() }
        },
      ]
    },
  },
  methods: {
    stopAccessToChat() {
      this.notify('stop access to chat')
      this.selectedChatParticipant = {}
    },
    blockMemberInChat (){
      this.notify('block member in the chat')
      this.selectedChatParticipant = {}
    },
    deleteMemberFromChat() {
      this.notify('del member from chat');
      this.selectedChatParticipant = {}
    },
  }
}
</script>


<style scoped lang="scss">
.scrollable {
  overflow-y: scroll;
  height: 100%;

  &::v-deep {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #4E6678;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #4E6678;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
</style>
