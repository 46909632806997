<template>
<v-sheet :color="wsDARKER" class="fill-height wsRoundedSemi overflow-hidden pa-6"  style="position:relative">

  <div class="wsRoundedSemi video-container" >

    <video
        id="canvasVideo"
        ref="canvasVideo"
        autoplay
        class="mx-auto d-flex video"
    />
    <!-- Main Stream Element-->
    <canvas
        class="mt-16"
        ref="canvas"
        width="640"
        height="480"
        style="margin: auto; padding: 0; background-color: yellowgreen;display:none"
    />

    <video
           v-for="(video,i) in activeVideoSources" :key="i"
           :id="video.value"
           :ref="video.value"
           autoplay
           class="mx-auto d-flex video"
           style="display:none !important"
    />

  </div>

</v-sheet>
</template>

<script>
import webinars from "@modules/webinars/mixins/webinars";
import WsJanusStreamer from "@modules/webinars/plugins/wsJanusStreamer";

export default {
  name: "webinarVideoControls",
  mixins: [webinars],
  data() {
    return {
      layout : 'Style 1',
      stream : null,
      videos : [],
      janus : null
    }
  },
  computed : {
    activeAudioSources() {
      return this.AUDIO_SOURCES.filter(el => !!el.active)
    },
    activeVideoSources() {
      return this.VIDEO_SOURCES.filter(el => !!el.active)
    }

  },
  watch : {
    CANVAS_CASCADE() {
      if (!this.janus) {
        return
      }
      this.janus.setCanvasCascadeType(this.CANVAS_CASCADE)
    },
    START_STREAM_TRIGGER() {
      this.startJanus()
    },
    VIDEO_SOURCES : {
      handler() {
        setTimeout(()=> {
          if (!this.janus) {
            return
          }
          this.notify('Janus Triggered with new sources')
          this.janus.setSources(this.getActiveVideoSources())
        },300)
      },
      deep : true
    }


  },
  methods : {

    getActiveAudioSource() {
      let sources = [...this.activeAudioSources]

      return sources[0]
    },

    getActiveVideoSources() {
      let sources = [...this.activeVideoSources]

      for (let source of sources) {

        let videoDom = this.$refs[source.value]
        if (videoDom ?? videoDom[0]) {
          videoDom = videoDom[0]
        }
        source.dom = videoDom

      }

      sources = sources.filter(el => !!el.dom)

      return sources
    },

    addStreamCallback(newStream) {
      this.$store.state.stream.streams.push(newStream)
    },

    removeStreamCallback() {
      this.notify('I want to delete')
    },
    async startJanus() {

      let result = await this.$store.dispatch('stream/GET_JANUS_START_TOKEN' , this.WEBINAR.uuid)
      if (!result) {
        this.ERROR(this.$store.state.ajax.error)
      }

      const sources = this.getActiveVideoSources()
      const audio = this.getActiveAudioSource()
      const canvas = this.$refs.canvas

      let janus = new WsJanusStreamer(result.server)
      this.janus = janus
      this.janus.setCanvasCascadeType(this.CANVAS_CASCADE)

      janus.startStream({
        token : result.token,
        roomId : this.WEBINAR.uuid,
        userId : this.$store.state.auth.userID,
        sources : sources,
        audio : audio,
        canvas : canvas,

        addCallback : this.addStreamCallback,
        removeCallback : this.removeStreamCallback,
        canvasCallback : (stream) => {
          const canvasVideo = this.$refs.canvasVideo
          canvasVideo.srcObject = stream
        }
      })

    }
  },

}
</script>


<style scoped>
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>