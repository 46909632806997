import cascadeSchemes from "@modules/webinars/plugins/streamCanvasBuilder/cascadeSchemes";

class StreamCanvasBuilder {
    constructor() {
    }

    /**
     * @param {CanvasRenderingContext2D} context - The canvas rendering context.
     * @param {Array} sources - The sources to draw on the canvas.
     * @param {HTMLCanvasElement} canvas
     * @param canvasCascadeType
     */
    drawCanvas(context , sources,canvas , canvasCascadeType) {

        context.clearRect(0, 0, canvas.width, canvas.height);

        if (sources.length === 1 ) {
            cascadeSchemes.default.drawCanvas(context,sources,canvas)
        }
        else if (cascadeSchemes[canvasCascadeType] && cascadeSchemes[canvasCascadeType].drawCanvas) {
            cascadeSchemes[canvasCascadeType].drawCanvas(context,sources,canvas)
        } else {
            cascadeSchemes.default.drawCanvas(context,sources,canvas)
        }

    }


}

export default StreamCanvasBuilder